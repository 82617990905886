import React from 'react'
import Accordion from 'react-bootstrap/Accordion';


function FaqTwo() {
    return (
        <div>
            {/* service accordion area */}
            <div className="rts-accordion-area rts-section-gap">
                <div className="accordion-service-bg bg_image ptb--120 ptb_md--80 ptb_sm--60">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="accordion-service-inner">
                                    <div className="title-area-start">
                                        <span className="sub color-primary">FREQUENTLY ASKED QUESTIONS</span>
                                        <h2 className="title">
                                        Your Questions, <br />   Answered
                                        </h2>
                                    </div>
                                    <div className="accordion-area">

                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                How can we help your business?</Accordion.Header>
                                                <Accordion.Body>
                                                Deploying a NanoSoft computer network solution to address what network is solution to address what ails your business technology can get you back ails help you grow your business again what network is solution to address what ails your business.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>
                                                How can I change my shipping address?</Accordion.Header>
                                                <Accordion.Body>
                                                Deploying a NanoSoft computer network solution to address what network is solution to address what ails your business technology can get you back ails help you grow your business again what network is solution to address what ails your business.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>
                                                How many free samples can i redeem?</Accordion.Header>
                                                <Accordion.Body>
                                                Deploying a NanoSoft computer network solution to address what network is solution to address what ails your business technology can get you back ails help you grow your business again what network is solution to address what ails your business.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>How can we help your business?</Accordion.Header>
                                                <Accordion.Body>
                                                Deploying a NanoSoft computer network solution to address what network is solution to address what ails your business technology can get you back ails help you grow your business again what network is solution to address what ails your business.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>Why must i make payment immediately <br /> at checkout?</Accordion.Header>
                                                <Accordion.Body>
                                                Deploying a NanoSoft computer network solution to address what network is solution to address what ails your business technology can get you back ails help you grow your business again what network is solution to address what ails your business.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* service accordion area End */}

        </div>
    )
}

export default FaqTwo