import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "./Breadcrumb";
import { Link } from 'react-router-dom';



import FooterOne from "../components/footer/FooterTwo";

function MarketingAutomation() {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Marketing Automation' }
    ];
    return (
        <div className=''>

            <HeaderOne />
            <Breadcrumb title="Marketing Automation" breadcrumbs={breadcrumbs} />

            {/* start service details area */}
            <div className="rts-service-details-area rts-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                            {/* service details left area start */}
                            <div className="service-detials-step-1">

                                <div className="row">
                                    <div className="col-sm-7  align-self-center">
                                        <div className="thumbnail">
                                            <img src="assets/images/service/01.jpg" alt="business-area" />
                                        </div>
                                    </div>
                                    <div className="col-sm-5 align-self-center">
                                        <h4 className="title text-end">Subscribe Ready2Ride’s Marketing Automation Portal                                       </h4>

                                        <div style={{
                                            background: "rgb(255 236 236)", padding: "25px", marginBottom: "25px", lineHeight: "36px",
                                            border: "1px solid #ffecec", borderRadius: "9px",
                                            display: "flex", justifyContent: "center",
                                            alignItems: "center", textAlign: "right"
                                        }}>

                                            <i style={{ fontSize: "24px", fontWeight: "400", color: "#000" }}>Streamline your operations effortlessly with Ready2Ride's marketing Portal exclusively built for Powersports Dealerships.</i>
                                        </div>
                                    </div>
                                </div>

                                <h4 className="title">Marketing Automation</h4>
                                <p className="disc">
                                Ready2Ride’s Marketing Automation Portal integrates with your DMS offering a centralized platform to consolidate and manage all your channels efficiently. It enables Powersports Dealerships to automate aftermarket sales and service campaigns across Email, SMS, MMS, and more to specific customer segments. This can save time, help you streamline marketing efforts, and boost marketing effectiveness across multi-channel platforms.                                </p>
                                <p className="disc">
                                With a range of features including coupon management, Turbo leads, and event tracking, Ready2Ride provides a comprehensive solution for dealers. The asset library allows the storage                                </p>
                                <p className="disc">
                                utilization of digital assets across different channels and customer segments, facilitating engagement at various stages of the customer lifecycle. Track campaign performance effortlessly with our data-driven analytics reports, covering automated flows, ad hoc campaigns, reputation management analysis, and digital services like Google Ads, Facebook, SEO, and others. This analysis enables you to understand ROI and optimize channel strategies for enhanced results.
                                </p>
                            </div>

                            {/* service details left area end */}
                            <div className="service-detials-step-3 mt--70 mt_md--50">
                                <div className="row g-5 align-items-center">
                                    <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="thumbnail sm-thumb-service">
                                            <img src="assets/images/service/sm-01.jpg" alt="Service" />
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 mb_md--20 mb_sm--20">
                                        <h4 className="title">Why Choose Us?</h4>
                                        <p className="disc">
                                        Our commitment to innovative strategies and data-driven approaches ensures that your brand experiences a significant 50% increase in reach, a remarkable 45% growth in website traffic, and an impressive 35% boost in qualified leads. We pride ourselves on our ability to transform your marketing challenges into triumphs, leveraging cutting-edge tools and expertise to enhance your digital presence. You can trust that your marketing investments will yield tangible, measurable outcomes, empowering your business to achieve sustainable growth and stay ahead in the competitive digital landscape. Let us be the catalyst for your brand’s elevated success.
                                        </p>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="cta-one-bg col-12">
                                    <div className="cta-one-inner pt-5 pb-5">
                                        <div className="cta-left">
                                            <h3 className="title">
                                            Transform Your Business Today with Ready2Ride!  
                                            </h3>
                                        </div>
                                        <div className="cta-right">
                                            <Link className="rts-btn btn-white" to={'https://clictechnologies.com/'} target="_blank">
                                            Contact Us
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* End service details area */}

            <FooterOne />

        </div>
    )
}

export default MarketingAutomation;