import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "./Breadcrumb";
import { Link } from 'react-router-dom';



import FooterOne from "../components/footer/FooterTwo";

function EnterpriseSolution() {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Enterprise Solution' }
    ];
    return (
        <div className=''>

            <HeaderOne />
            <Breadcrumb title="Enterprise Solution" breadcrumbs={breadcrumbs} />

            {/* start service details area */}
            <div className="rts-service-details-area rts-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                            {/* service details left area start */}
                            <div className="service-detials-step-1">

                                <div className="row">
                                    <div className="col-sm-7  align-self-center">
                                        <div className="thumbnail">
                                            <img src="assets/images/service/01.jpg" alt="business-area" />
                                        </div>
                                    </div>
                                    <div className="col-sm-5 align-self-center">
                                        <h4 className="title text-end">Empowering Businesses with Scalable, Integrated Technology                                       </h4>

                                        <div style={{
                                            background: "rgb(255 236 236)", padding: "25px", marginBottom: "25px", lineHeight: "36px",
                                            border: "1px solid #ffecec", borderRadius: "9px",
                                            display: "flex", justifyContent: "center",
                                            alignItems: "center", textAlign: "right"
                                        }}>

                                            <i style={{ fontSize: "24px", fontWeight: "400", color: "#000" }}>our Enterprise Solutions are designed to support businesses as they grow and scale in today’s fast-paced digital landscape.</i>
                                        </div>
                                    </div>
                                </div>

                                <h4 className="title">Enterprise Solution</h4>
                                <p className="disc">
                                With a focus on seamless integration, data-driven insights, and enhanced collaboration, our solutions provide the robust infrastructure and tools needed for organizations to operate efficiently and stay competitive. Whether it’s managing extensive data, optimizing workflow processes, or enabling real-time collaboration across departments, our enterprise solutions are tailored to meet the unique demands of large-scale operations.                                </p>
                                <p className="disc">
                                Our services include enterprise resource planning (ERP), customer relationship management (CRM), data analytics, and customized applications that align with your organization’s strategic goals. By centralizing data and streamlining workflows, we help reduce operational complexities                                </p>
                                <p className="disc">
                                improve decision-making, and foster a more responsive business environment. Each solution is crafted to be scalable, allowing your organization to adapt quickly to changing demands while ensuring continuity and resilience.                                </p>
                            </div>

                            {/* service details left area end */}
                            <div className="service-detials-step-3 mt--70 mt_md--50">
                                <div className="row g-5 align-items-center">
                                    <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="thumbnail sm-thumb-service">
                                            <img src="assets/images/service/sm-01.jpg" alt="Service" />
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 mb_md--20 mb_sm--20">
                                        <h4 className="title">Why Choose Us?</h4>
                                        <p className="disc">
                                        Partnering with a team that understands the intricacies of enterprise-level challenges and offers scalable, secure, and future-ready solutions. We work closely with your teams to understand specific pain points and operational goals, creating a tailored roadmap to enhance efficiency and productivity across the organization. With our expertise, we align long-term vision and empower your business with measurable growth.                                         </p>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="cta-one-bg col-12">
                                    <div className="cta-one-inner pt-5 pb-5">
                                        <div className="cta-left">
                                            <h3 className="title">
                                            Transform Your Business Today with Ready2Ride!  
                                            </h3>
                                        </div>
                                        <div className="cta-right">
                                            <Link className="rts-btn btn-white" to={'https://clictechnologies.com/'} target="_blank">
                                            Contact Us
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* End service details area */}

            <FooterOne />

        </div>
    )
}

export default EnterpriseSolution;