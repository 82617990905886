import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "./Breadcrumb";
import { Link } from 'react-router-dom';



import FooterOne from "../components/footer/FooterTwo";


function AiMlServicePageDetailsCom() {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'AI / Ml Service Details' }
    ];
    return (
        <div className=''>

            <HeaderOne />
            <Breadcrumb title="AI/Ml Service Details" breadcrumbs={breadcrumbs} />

            {/* start service details area */}
            <div className="rts-service-details-area rts-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                            {/* service details left area start */}
                            <div className="service-detials-step-1">

                                <div className="row">
                                    <div className="col-sm-7  align-self-center">
                                        <div className="thumbnail">
                                            <img src="assets/images/service/01.jpg" alt="business-area" />
                                        </div>
                                    </div>
                                    <div className="col-sm-5 align-self-center">
                                        <h4 className="title text-end">Transforming the Future of Innovation</h4>

                                        <div style={{
                                            background: "rgb(255 236 236)", padding: "25px", marginBottom: "25px", lineHeight: "36px",
                                            border: "1px solid #ffecec", borderRadius: "9px",
                                            display: "flex", justifyContent: "center",
                                            alignItems: "center", textAlign: "right"
                                        }}>

                                            <i style={{ fontSize: "24px", fontWeight: "400", color: "#000" }}>Our SaaS platforms utilize advanced marketing automation tools <br /> that incorporate AI and ML algorithms.</i>
                                        </div>
                                    </div>
                                </div>

                                <h4 className="title">AI Integration</h4>
                                <p className="disc">
                                    Leveraging AI-driven intelligence, we empower businesses to analyze extensive customer data, enabling data-informed decision-making and personalized
                                    marketing strategies. We harness the power of AI-driven intelligence to help businesses unlock the potential of their customer data. Our software is built to handle complex tasks and workflows with increased efficiency and precision. By incorporating automated decision-making, we significantly reduce the need for manual intervention, which saves both time and resources to streamline operations and focus on more strategic objectives.
                                </p>
                                <p className="disc">
                                    AI-powered features further enhance the user experience by delivering personalized recommendations tailored to individual needs. With generative AI, our talented creatives can concentrate on developing big ideas and driving innovation, while AI takes care of content creation, providing customers with impactful and timely solutions. Integrating with AI/ML technologies can revolutionize focus on big ideas and innovation by automating
                                    complex tasks ensuring stay ahead in a rapidly evolving market.
                                </p>

                            </div>

                            {/* service details left area end */}
                            <div className="service-detials-step-3 mt--70 mt_md--50 rts-section-gapBottom">
                                <div className="row g-5 align-items-center">
                                    <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="thumbnail sm-thumb-service">
                                            <img src="assets/images/service/sm-01.jpg" alt="Service" />
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 mb_md--20 mb_sm--20">
                                        <h4 className="title">Why Choose Us?</h4>
                                        <p className="disc">
                                            We don’t just stop at innovation; we focus on delivering personalized solutions tailored to your unique business needs with cutting-edge AI and machine learning solutions with a customer-first approach to deliver superior results. Our expertise in data analytics, AI-driven automation, and database management allows us to handle
                                            complex business challenges efficiently and effectively.
                                        </p>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="cta-one-bg col-12">
                                    <div className="cta-one-inner pt-5 pb-5">
                                        <div className="cta-left">
                                            <h3 className="title">
                                                Ready to Revolutionize Your Business Operations?
                                            </h3>
                                        </div>
                                        <div className="cta-right">
                                            <Link className="rts-btn btn-white" to={'https://clictechnologies.com/'} target="_blank">
                                                Contact us today!
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* End service details area */}

            <FooterOne />

        </div>
    )
}

export default AiMlServicePageDetailsCom;