import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Navigation, Scrollbar, A11y, EffectFade, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

function TestimonialThree() {


    return (
        <div>
            {/* testimonials area start */}
            <div className="rts-testimonials-h2-area rts-section-gap bg_testimonials-h2">
                <div className="container">
                    <div className="row mb--30">
                        <div className="col-12">
                            <div className="title-area testimonials-area-h2 text-center">
                                <span style={{color:"#df0a0a"}}>
                                Testimonial
                                </span>
                                <h2  style={{color:"#1c2539"}}
                                    className="title">
                                  What do people praise <br />about Warrous?
 
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row g-5">
                        <div className="col-12">
                            {/* Swiper */}
                            <div className="swiper testimonials-h2">
                                <Swiper
                                    // install Swiper modules
                                    modules={[Navigation, EffectFade, Scrollbar, A11y, Autoplay]}
                                    className="testimonials-h2"
                                    speed={700}
                                    slidesPerView={1}
                                    spaceBetween={30}
                                    loop={true}
                                    autoplay={true}
                                    pagination={{
                                        clickable: true,
                                        el: 'swiper-pagination2'
                                    }}
                                    breakpoints={{
                                        1200: { slidesPerView: 2 },
                                        900: { slidesPerView: 2 },
                                        768: { slidesPerView: 1 },
                                        580: { slidesPerView: 1 },
                                        450: { slidesPerView: 1 },
                                        0: { slidesPerView: 1 },
                                    }}
                                >
                                    <SwiperSlide>
                                        {/* single testimonials area */}
                                        <div className="single-testimonials-h2">
                                            <div className="body">
                                               
                                                <p className="disc">
                                                    “I have known the company and seen it growing for the last few years. The level of professionalism they possessed right from the early days of the company till date is truly remarkable. They are on point and its always good to have an organization provide 
                                                    services with actual domain knowledge. There are no smoking mirrors when you deal with them.”
                                                </p>
                                            </div>
                                            <div className="footer">
                                                <div className="left">
                                                    <a className="thumbnail" href="/"> 
                                                        <img
                                                            src="assets/images/testimonials/07.png"
                                                            alt="testimonials_image"
                                                        />
                                                    </a>
                                                    <div className="desig">
                                                        <a href="/">
                                                            <h6 className="title">Kang Qiang </h6>
                                                        </a>
                                                        <p>
                                                        Marketing Officer <span></span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="right">
                                                    <div className="stars-area">
                                                        <ul>
                                                            <li>
                                                                <a href="/">
                                                                    <i className="fas fa-star"  style={{color:"#df0a0a"}}/>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="/">
                                                                    <i className="fas fa-star"   style={{color:"#df0a0a"}}/>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="/">
                                                                    <i className="fas fa-star"   style={{color:"#df0a0a"}}/>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="/">
                                                                    <i className="fas fa-star"  style={{color:"#df0a0a"}} />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="/">
                                                                    <i className="fas fa-star"  style={{color:"#df0a0a"}} />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* single testimonials area End */}
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        {/* single testimonials area */}
                                        <div className="single-testimonials-h2">
                                            <div className="body">
                                                
                                                <p className="disc">
                                                    “What I liked most about you guys is your professionalism and prompt responses. In the early stages of building the software as I explained about the nuances of my business operation, your suggestions and constructive criticism did real good benefit to my whole business operation. I would also like to mention about the eye for details that the team have. My heartiest best wishes to you all.”
                                                </p>
                                            </div>
                                            <div className="footer">
                                                <div className="left">
                                                    <a className="thumbnail" href="/">
                                                        <img
                                                            src="assets/images/testimonials/07.png"
                                                            alt="testimonials_image"
                                                        />
                                                    </a>
                                                    <div className="desig">
                                                        <a href="/">
                                                            <h6 className="title">Kang Qiang </h6>
                                                        </a>
                                                        <p>
                                                        Marketing Officer <span></span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="right">
                                                    <div className="stars-area">
                                                    <ul>
                                                            <li>
                                                                <a href="/">
                                                                    <i className="fas fa-star"  style={{color:"#df0a0a"}}/>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="/">
                                                                    <i className="fas fa-star"   style={{color:"#df0a0a"}}/>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="/">
                                                                    <i className="fas fa-star"   style={{color:"#df0a0a"}}/>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="/">
                                                                    <i className="fas fa-star"  style={{color:"#df0a0a"}} />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="/">
                                                                    <i className="fas fa-star"  style={{color:"#df0a0a"}} />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* single testimonials area End */}
                                    </SwiperSlide>
                                
                                 


                                </Swiper>
                            </div>
                            {/* swiper end */}
                        </div>

                        <div className="swiper-pagination2" />
                    </div>
                </div>
            </div>
            {/* testimonials area end */}

        </div>
    )
}

export default TestimonialThree