import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "./Breadcrumb";
import { Link } from 'react-router-dom';



import FooterOne from "../components/footer/FooterTwo";

function QualityAssuranceSoftwareTesting() {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Quality Assurance & Software Testing' }
    ];
    return (
        <div className=''>

            <HeaderOne />
            <Breadcrumb title="Quality Assurance & Software Testing" breadcrumbs={breadcrumbs} />

            {/* start service details area */}
            <div className="rts-service-details-area rts-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                            {/* service details left area start */}
                            <div className="service-detials-step-1">

                                <div className="row">
                                    <div className="col-sm-7  align-self-center">
                                        <div className="thumbnail">
                                            <img src="assets/images/service/01.jpg" alt="business-area" />
                                        </div>
                                    </div>
                                    <div className="col-sm-5 align-self-center">
                                        <h4 className="title text-end">Ensuring Excellence at Every Step                                        </h4>

                                        <div style={{
                                            background: "rgb(255 236 236)", padding: "25px", marginBottom: "25px", lineHeight: "36px",
                                            border: "1px solid #ffecec", borderRadius: "9px",
                                            display: "flex", justifyContent: "center",
                                            alignItems: "center", textAlign: "right"
                                        }}>

                                            <i style={{ fontSize: "24px", fontWeight: "400", color: "#000" }}>We identify and address potential issues early, safeguarding the reliability and functionality of your software. </i>
                                        </div>
                                    </div>
                                </div>

                                <h4 className="title">Quality Assurance & Software Testing</h4>
                                <p className="disc">
                                    We prioritize quality in every solution we deliver. Our Quality Assurance (QA) and Software Testing services are designed to ensure that each product meets the highest standards of performance, security, and user experience. Through rigorous testing processes and advanced QA methodologies.                                </p>
                                <p className="disc">
                                    Our testing approach spans the entire software lifecycle, incorporating functional, performance, security, and usability checks to cover all critical aspects.
                                </p>
                                <p className="disc">
                                    With a focus on both manual and automated testing, we provide detailed, data-driven insights, helping to optimize and streamline your application covers at every aspect of the software lifecycle. we provide detailed, data-driven insights, helping to optimize and streamline your applications by customizing our QA strategies to align with your project requirements, ensuring the final product not only meets but exceeds your expectations.                                </p>
                            </div>

                            {/* service details left area end */}
                            <div className="service-detials-step-3 mt--70 mt_md--50">
                                <div className="row g-5 align-items-center">
                                    <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="thumbnail sm-thumb-service">
                                            <img src="assets/images/service/sm-01.jpg" alt="Service" />
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 mb_md--20 mb_sm--20">
                                        <h4 className="title">Why Choose Us?</h4>
                                        <p className="disc">
                                            One can trust that your software will be launched with confidence, backed by thorough testing and quality assurance processes that set the foundation for lasting performance and customer satisfaction.                                        </p>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="cta-one-bg col-12">
                                    <div className="cta-one-inner pt-5 pb-5">
                                        <div className="cta-left">
                                            <h3 className="title">
                                                Transform Your Business Today with Ready2Ride!
                                            </h3>
                                        </div>
                                        <div className="cta-right">
                                            <Link className="rts-btn btn-white" to={'https://clictechnologies.com/'} target="_blank">
                                                Contact Us
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* End service details area */}

            <FooterOne />

        </div>
    )
}

export default QualityAssuranceSoftwareTesting;