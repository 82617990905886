import React from 'react'
import Accordion from 'react-bootstrap/Accordion';

function FaqOne() {
    return (
        <div>
            {/* rts faq section area */}
            <div className="rts-faq-section rts-section-gap rts-faq-bg bg_image ">
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-lg-12">
                        <div className="faq-two-inner">
                        <div className="title-area-faq">
                                    {/* <span className="sub">JUST A CONSULTANCY</span> */}
                                    <h2 className="title">
                                    Pioneering Digital  <br />
                                        <span className="sm-title">
                                          <span>Innovation & Excellence</span>
                                        </span>
                                    </h2>
                                    <p>At Warrous, we are dedicated to empowering businesses by crafting top-tier
                                         digital products through our specialized services focused on innovation, quality, and exceeding customer satisfaction.</p>
                                </div>
                                </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="faq-two-inner">
                              
                                {/* faq accordion area */}
                                <div className="faq-accordion-area">
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header><span>01. </span>Experience and Expertise </Accordion.Header>
                                            <Accordion.Body>
                                            Our highly skilled team is equipped with the latest technologies. As a leading software development company, we collaborate
                                             with you to comprehend your business challenges and construct an effective project roadmap tailored to your needs.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>
                                                <span>02. </span>Unmatched Quality
                                            </Accordion.Header>
                                            <Accordion.Body>
                                            Leveraging our team of experts, we go the extra mile to deliver high-quality, reliable, and scalable solutions. 
                                            Our software and IT services are meticulously designed to help you achieve your business objectives efficiently.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>
                                                <span>03. </span> Cutting-Edge Solutions
                                            </Accordion.Header>
                                            <Accordion.Body>
                                            Staying ahead of technology trends, we integrate the latest innovations into your project roadmap.
                                             Our approach ensures that your project benefits from creative, out-of-the-box thinking, from concept to completion.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>
                                                <span>04. </span> Market-Driven Expertise
                                            </Accordion.Header>
                                            <Accordion.Body>
                                            Our breadth of industry knowledge and keen understanding of market dynamics set us apart.
                                             We identify the most effective methodologies that align with your business goals in providing a unique edge.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header><span>05. </span>Competitive Pricing </Accordion.Header>
                                            <Accordion.Body>
                                            By utilizing top-tier tools and proven methodologies, coupled with expert services,
                                             we strive to deliver the best return on investment and ensure that you receive superior value without compromising on quality.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                                {/* faq accordion area end */}
                            </div>
                        </div>
                        <div className="col-lg-6">
                        
                            <div className="thumbnail-faq-four">
                                <img src="assets/images/faq/02.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rts faq section area End */}
        </div>
    )
}

export default FaqOne