import React  from 'react';  
import HeaderOne from '../header/HeaderOne';
import FooterOne from '../footer/FooterOne';
import Breadcrumb from '../../inner/Breadcrumb';
import FaqTwo from '../faq/FaqTwo';   
import FooterTwo from '../footer/FooterTwo'; 
function FaqComp() {

    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Careers' }
    ];
     
    return (
        <div>
              <HeaderOne />
              <Breadcrumb title="Careers" breadcrumbs={breadcrumbs} />
            <FaqTwo/>
            {/* <TestimonialThree/> */}
             
            <FooterTwo />
        </div>
    )
}

export default FaqComp;