import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Nav from './Nav';


function SideMenu({ isSidebarOpen, toggleSidebar }) {

    const [openMenu, setOpenMenu] = useState(null);

    const toggleMenu = (menuhome) => {
        setOpenMenu(openMenu === menuhome ? null : menuhome);
    };


    return (
        <div>
            <div id="side-bar" className={`side-bar ${isSidebarOpen ? 'show' : ''}`}>
                <button className="close-icon-menu" aria-label="Close Menu" onClick={toggleSidebar}>
                    <i className="far fa-times"></i>
                </button>
                {/* inner menu area desktop start */}
                <div className="rts-sidebar-menu-desktop">
                    {/* <Link className="logo-1" href="index.html">
                        <img
                            className="logo"
                            src="/assets/images/logo/logo.png"
                            alt="finbiz_logo"
                        />
                    </Link> */}
                    
                    <div className="body-mobile">
                        <Nav></Nav>
                     
                        <div className="social-wrapper-two menu mobile-menu">
                            <Link to={'https://www.linkedin.com/company/warrous-pvt-ltd/'} target='_blank'>
                                <i className="fab fa-linkedin" />
                            </Link>
                            {/* <Link href="/"><i class="fab fa-linkedin"></i></Link> */}
                        </div>
                        <Link
                            href="/"
                            className="rts-btn btn-primary ml--20 ml_sm--5 header-one-btn quote-btnmenu"
                        >
                            Contact Us
                        </Link>
                    </div>
                </div>
                {/* inner menu area desktop End */}
            </div>


        </div>
    )
}

export default SideMenu