import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "./Breadcrumb";
import { Link } from 'react-router-dom';



import FooterOne from "../components/footer/FooterTwo"; 
import ServiceCrmCmp from '../components/service/ServiceCrm';

function CrmCom() {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'CRM' }
    ];
    return (
        <div className=''>

            <HeaderOne />
            <Breadcrumb title="CRM" breadcrumbs={breadcrumbs} />

            {/* start service details area */}
            <div className="rts-service-details-area rts-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                            {/* service details left area start */}
                            <div className="service-detials-step-1">

                                <div className="row">
                                    <div className="col-sm-7  align-self-center">
                                        <div className="thumbnail">
                                            <img src="assets/images/service/01.jpg" alt="business-area" />
                                        </div>
                                    </div>
                                    <div className="col-sm-5 align-self-center">
                                        <h4 className="title text-end">Manage Leads, Drive Conversions, & Boost Sales</h4>

                                        <div style={{
                                            background: "rgb(255 236 236)", padding: "25px", marginBottom: "25px", lineHeight: "36px",
                                            border: "1px solid #ffecec", borderRadius: "9px",
                                            display: "flex", justifyContent: "center",
                                            alignItems: "center", textAlign: "right"
                                        }}>

                                            <i style={{ fontSize: "24px", fontWeight: "400", color: "#000" }}>Our advanced CRM tool streamlines lead management, and task automation while providing in-depth reporting and efficient escalation handling for unparalleled accuracy and effectiveness.
                                            </i>
                                        </div>
                                    </div>
                                </div>

                                <h4 className="title">CRM - One Platform, Multiple Benefits</h4>
                                <p className="disc">
                                At Warrous, we understand the fast-paced demands of businesses, which is why our CRM solution is designed to streamline your day-to-day operations and amplify your success. Say goodbye to the chaos of multiple spreadsheets and databases. Our platform integrates all your data seamlessly, optimizing lead management, automating tasks, nurturing customer communications, and generating in-depth reports. Whether you're addressing customer inquiries or scheduling appointments on the go, our CRM Mobile App ensures you have everything you need at your fingertips.
                                </p>
                                <p className="disc">
                                Our cutting-edge CRM tool stands out with its user-friendly interface and robust features. From bulk marketing capabilities to predictive analytics, the tool provides actionable insights that drive growth and enhance customer satisfaction. Experience the power of a 360-degree view of your customers, and improve efficiency across all your dealership functions with just a few clicks.
                                </p>

                            </div>
                          
                            
                            {/* service details left area end */}

                            {/* <ServiceCrmCmp />  */}
                             

                            
                        </div>

                    </div>
                </div>
            </div>
            {/* End service details area */}

            <FooterOne />

        </div>
    )
}

export default CrmCom;