import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "./Breadcrumb";
import { Link } from 'react-router-dom';



import FooterOne from "../components/footer/FooterTwo";

function DigitalMarketting() {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Digital Marketing' }
    ];
    return (
        <div className=''>

            <HeaderOne />
            <Breadcrumb title="Digital Marketing" breadcrumbs={breadcrumbs} />

            {/* start service details area */}
            <div className="rts-service-details-area rts-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                            {/* service details left area start */}
                            <div className="service-detials-step-1">

                                <div className="row">
                                    <div className="col-sm-7  align-self-center">
                                        <div className="thumbnail">
                                            <img src="assets/images/service/01.jpg" alt="business-area" />
                                        </div>
                                    </div>
                                    <div className="col-sm-5 align-self-center">
                                        <h4 className="title text-end">Elevate your Brand & Drive Measurable ROI                                        </h4>

                                        <div style={{
                                            background: "rgb(255 236 236)", padding: "25px", marginBottom: "25px", lineHeight: "36px",
                                            border: "1px solid #ffecec", borderRadius: "9px",
                                            display: "flex", justifyContent: "center",
                                            alignItems: "center", textAlign: "right"
                                        }}>

                                            <i style={{ fontSize: "24px", fontWeight: "400", color: "#000" }}>Our Digital Marketing Services are designed to  increase the online visibility of your enterprise, and achieve sustainable growth. </i>
                                        </div>
                                    </div>
                                </div>

                                <h4 className="title">Digital Marketing</h4>
                                <p className="disc">
                                We offer a comprehensive suite of digital marketing services meticulously designed to elevate your brand’s presence and maximize ROI. Our robust strategies begin with targeted Search Engine Optimization (SEO) to enhance search rankings and boost online visibility, ensuring that your business stands out to potential customers. Complementing this, our Search Engine Marketing (SEM) and custom tailored Meta Ads are crafted to engage your target audiences, effectively driving improved revenue streams. We address prevalent marketing challenges by focusing on improving conversion rates through expert SEO practices and providing real-time analytics that offer insights into campaign effectiveness, enabling informed, data-driven decisions.
                                </p>
                                <p className="disc">
                                Moreover, we extend our expertise to Social Media Marketing on platforms like Meta and Instagram, optimizing your Google Business Profile for better customer engagement. 
                                </p>
                                <p className="disc">
                                Our innovative approach embraces new-age marketing channels, including OTT/Connected TV, TikTok, Snapchat, and Programmatic Ads, ensuring your marketing dollars yield maximum impact. These advanced tools allow us to connect you with your target audience efficiently, fostering sustainable growth and solidifying your brand's footprint in the digital landscape. By leveraging these diverse and cutting-edge strategies, we ensure a holistic approach to marketing, tailored to meet unique business objectives and drive long-term success.
                                </p>
                            </div>

                            {/* service details left area end */}
                            <div className="service-detials-step-3 mt--70 mt_md--50">
                                <div className="row g-5 align-items-center">
                                    <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="thumbnail sm-thumb-service">
                                            <img src="assets/images/service/sm-01.jpg" alt="Service" />
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 mb_md--20 mb_sm--20">
                                        <h4 className="title">Why Choose Us?</h4>
                                        <p className="disc">
                                        Our commitment to innovative strategies and data-driven approaches ensures that your brand experiences a significant 50% increase in reach, a remarkable 45% growth in website traffic, and an impressive 35% boost in qualified leads. We pride ourselves on our ability to transform your marketing challenges into triumphs, leveraging cutting-edge tools and expertise to enhance your digital presence. You can trust that your marketing investments will yield tangible, measurable outcomes, empowering your business to achieve sustainable growth and stay ahead in the competitive digital landscape. Let us be the catalyst for your brand’s elevated success.
                                        </p>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="cta-one-bg col-12">
                                    <div className="cta-one-inner pt-5 pb-5">
                                        <div className="cta-left">
                                            <h3 className="title">
                                            Transform Your Business Today with Ready2Ride!  
                                            </h3>
                                        </div>
                                        <div className="cta-right">
                                            <Link className="rts-btn btn-white" to={'https://clictechnologies.com/'} target="_blank">
                                            Contact Us
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* End service details area */}

            <FooterOne />

        </div>
    )
}

export default DigitalMarketting;