import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "./Breadcrumb";
import { Link } from 'react-router-dom';



import FooterOne from "../components/footer/FooterTwo";

function DevOpsandAutomation() {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'DevOps and Automation' }
    ];
    return (
        <div className=''>

            <HeaderOne />
            <Breadcrumb title="DevOps and Automation" breadcrumbs={breadcrumbs} />

            {/* start service details area */}
            <div className="rts-service-details-area rts-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                            {/* service details left area start */}
                            <div className="service-detials-step-1">

                                <div className="row">
                                    <div className="col-sm-7  align-self-center">
                                        <div className="thumbnail">
                                            <img src="assets/images/service/01.jpg" alt="business-area" />
                                        </div>
                                    </div>
                                    <div className="col-sm-5 align-self-center">
                                        <h4 className="title text-end">Streamlining Development for Faster Innovation                                       </h4>

                                        <div style={{
                                            background: "rgb(255 236 236)", padding: "25px", marginBottom: "25px", lineHeight: "36px",
                                            border: "1px solid #ffecec", borderRadius: "9px",
                                            display: "flex", justifyContent: "center",
                                            alignItems: "center", textAlign: "right"
                                        }}>

                                            <i style={{ fontSize: "24px", fontWeight: "400", color: "#000" }}>We bridge the gap between development and operations, fostering collaboration and efficiency across your teams. </i>
                                        </div>
                                    </div>
                                </div>

                                <h4 className="title">DevOps and Automation</h4>
                                <p className="disc">
                                Warrous specializes in DevOps and Automation solutions that streamline the development process, enabling faster, more reliable software delivery. By integrating DevOps practices and automation tools into your workflows. Our approach automates repetitive tasks, accelerates deployment cycles, and improves resource utilization, allowing businesses to bring high-quality products to market faster.                                </p>
                                <p className="disc">
                                Our DevOps solutions include continuous integration and continuous delivery (CI/CD), infrastructure as code (IaC), automated testing, and monitoring, all of which contribute to a more agile and resilient development environment.                                </p>
                                <p className="disc">
                                By implementing robust automation strategies, we help reduce errors, improve scalability, and ensure consistent performance across your applications. Our team works closely with you to tailor DevOps pipelines that align with your unique requirements, transforming complex workflows into streamlined, predictable processes.
                                </p>
                            </div>

                            {/* service details left area end */}
                            <div className="service-detials-step-3 mt--70 mt_md--50">
                                <div className="row g-5 align-items-center">
                                    <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="thumbnail sm-thumb-service">
                                            <img src="assets/images/service/sm-01.jpg" alt="Service" />
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 mb_md--20 mb_sm--20">
                                        <h4 className="title">Why Choose Us?</h4>
                                        <p className="disc">
                                        Gaining a partner committed to your efficiency and growth. Our team brings in-depth knowledge of industry-leading tools, practices, and frameworks, ensuring we deliver scalable, secure, and optimized solutions. We prioritize transparency and adaptability, working alongside your teams to foster a collaborative environment that supports continuous improvement.                                         </p>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="cta-one-bg col-12">
                                    <div className="cta-one-inner pt-5 pb-5">
                                        <div className="cta-left">
                                            <h3 className="title">
                                            Transform Your Business Today with Ready2Ride!  
                                            </h3>
                                        </div>
                                        <div className="cta-right">
                                            <Link className="rts-btn btn-white" to={'https://clictechnologies.com/'} target="_blank">
                                            Contact Us
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* End service details area */}

            <FooterOne />

        </div>
    )
}

export default DevOpsandAutomation;