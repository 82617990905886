import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import BannerOne from "../components/banner/BannerOne";
// import AboutOne from "../components/about/AboutOne";
import ServiceOne from "../components/service/ServiceOne";
import BusinessGoalOne from "../components/businessgoal/BusinessGoalOne";
// import Counterup from "../components/counterup/Counterup";
// import GalleryOne from "../components/gallery/GalleryOne";
// import BrandOne from "../components/brand/BrandOne";
// import TeamOne from "../components/team/TeamOne";
import Feature from "../components/feature/Feature";
import TestimonialOne from "../components/testimonials/TestimonialOne";



import FooterOne from "../components/footer/FooterTwo";
import FaqOne from '../components/faq/FaqOne';
import OurServiceComp from '../components/service/Ourservice';

function HomeOne() {
  return (
    <div>

      <HeaderOne />
      <BannerOne />
      <OurServiceComp />
      {/* <AboutOne /> */}
      <ServiceOne />
       <FaqOne />
      {/* <BusinessGoalOne /> */}
     
      {/* <Counterup /> */}

      {/* <GalleryOne /> */}
      {/* <BrandOne /> */}
      {/* <TeamOne /> */}


      <div style={{height:"203px"}}></div>
      <Feature />
   
      <TestimonialOne />
      {/* <BlogOne /> */}
      {/* <ContactForm /> */}




      <FooterOne />

    </div>
  )
}

export default HomeOne