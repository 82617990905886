import React from 'react'
import { Link } from 'react-router-dom'

function Feature() {
    return (
        <div>
            {/* rts features area start */}
            <div className="rts-feature-area rts-section-gap ">
                <div className="container-fluid padding-controler plr--120">
                    <div className="row bg-white-feature  pt--120 pb--120">
                        <div className="col-xl-6 col-lg-12">
                            <div className="feature-left-area">
                                <img src="assets/images/feature/01.png" alt="" />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12">
                            <div className="rts-title-area feature text-start pl--30 pl_sm--0">
                                <p className="pre-title">Boost Your Business Today</p>
                                <h2 className="title feature-title">
                                    Tackling Complex Challenges <br /> Across Industries
                                </h2>
                                <p>
                                    At Warrous, we pride ourselves on delivering meticulous, high-quality services. We partner with you to provide long-term, impactful results that drive sustained success.
                                </p>
                            </div>
                            <div className="feature-one-wrapper pl--30 mt--40 pl_sm--0">
                                <div className="single-feature-one">
                                    <i className="fal fa-check" />
                                    <Link to={'/omni-channel'}>Automotive</Link>
                                </div>
                                <div className="single-feature-one">
                                    <i className="fal fa-check" />
                                    <Link to={'/crm'}>Financial</Link>
                                </div>
                                <div className="single-feature-one">
                                    <i className="fal fa-check" />
                                    <Link to={'/digital-marketing'}>Advertising</Link>
                                </div>
                                <div className="single-feature-one">
                                    <i className="fal fa-check" />
                                    <Link to={'/omni-channel'}>Powersports</Link>
                                </div>
                                <div className="single-feature-one">
                                    <i className="fal fa-check" />
                                    <Link to={'/crm'}>Telematics</Link>
                                </div>
                                <div className="single-feature-one">
                                    <i className="fal fa-check" />
                                    <Link to={'/ai-ml-servicepage'}>IoT</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rts features area end */}
        </div>
    )
}

export default Feature