import React  from 'react';  
import HeaderOne from '../header/HeaderOne';
import FooterOne from '../footer/FooterOne';
import Breadcrumb from '../../inner/Breadcrumb';   
import TestimonialThree from '../testimonials/TestimonialThree';
import FooterTwo from '../footer/FooterTwo'; 
function TestimonialComp() {

    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Testimonial' }
    ];
     
    return (
        <div>
              <HeaderOne />
              <Breadcrumb title="Testimonial" breadcrumbs={breadcrumbs} />
          
            <TestimonialThree/>
             
            <FooterTwo />
        </div>
    )
}

export default TestimonialComp;