import React from 'react'
function BusinessGoalFour() {
    return (
        <div>
            {/* rts service area start */}
            <div className="rts-service-area home-seven " id='service'>
                <div className="shape-business-service">
                    <img src="assets/images/service/icon/shape-1.png" alt="shape" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="title-area-with-btn-home-6">
                                <div className="title-area-style-six text-start">
                                    <div className="pre-title">
                                       
                                        <span className="pre">How we do it?</span>
                                        <img
                                            className="two"
                                            src="assets/images/banner/shape/pre-title.png"
                                            alt="pre-title"
                                        />
                                    </div>
                                    <h2 className="title">
                                    Putting an extra bit of effort<br />
                                    at every step.
                                    </h2>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                    <div className="row g-5 mt--80">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="service-single-style-six">
                                <div className="icon">
                                    <img src="assets/images/service/icon/22.svg" alt="service_icon" />
                                </div>
                                <h5 className="title">Understand Objectives</h5>
                                <p className="disc">
                                We dive deep to understand the desired end-user behavior you want to see and 
                                begin developing the right user experience to make that behavior happen.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="service-single-style-six">
                                <div className="icon">
                                    <img src="assets/images/service/icon/23.svg" alt="service_icon" />
                                </div>
                                <h5 className="title">Choose Right ​​​​​​​Technology</h5>
                                <p className="disc">
                                Our focus always is to make the code device friendly,
                                 so we help you choose the right technology.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="service-single-style-six">
                                <div className="icon">
                                    <img src="assets/images/service/icon/24.svg" alt="service_icon" />
                                </div>
                                <h5 className="title">Documented Coding</h5>
                                <p className="disc">
                                From beautiful design to a custom-coded website, the development phase brings your digital vision to life.
                                </p>
                            </div>
                        </div>
                        </div>
                        <div className="row g-5 mt--80">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mt-5">
                            <div className="service-single-style-six">
                                <div className="icon">
                                    <img src="assets/images/service/icon/24.svg" alt="service_icon" />
                                </div>
                                <h5 className="title">Rigorous Testing</h5>
                                <p className="disc">
                                Our rigorous QA process includes testing speed, security, and overall functionality.
                                 We catch any potential problems before they happen.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mt-5">
                            <div className="service-single-style-six">
                                <div className="icon">
                                    <img src="assets/images/service/icon/24.svg" alt="service_icon" />
                                </div>
                                <h5 className="title">Successful Deployment</h5>
                                <p className="disc">
                                Deploy to multiple environments like staging and production to 
                                successfully launch the websites.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mt-5">
                            <div className="service-single-style-six">
                                <div className="icon">
                                    <img src="assets/images/service/icon/24.svg" alt="service_icon" />
                                </div>
                                <h5 className="title">24/7 Support</h5>
                                <p className="disc">
                                Our experts ensure to maintain a website or app’s smooth performance using the latest and advanced
                                 software for our valued clients.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rts service area end */}
        </div>
    )
}

export default BusinessGoalFour