import React from 'react';
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "./Breadcrumb";
import FooterOne from "../components/footer/FooterTwo";
import AboutTwo from '../components/about/AboutTwo';



function OurService() {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'About Us' }
    ];
    

   

   
    return (
        <div className=''>

            <HeaderOne />
            <Breadcrumb title="About Us" breadcrumbs={breadcrumbs} />
            {/* rts about us section start */}
            <AboutTwo />
            <FooterOne />

        </div>
    )
}

export default OurService