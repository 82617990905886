import React from 'react'

function MarketingSolution() {
    return (
        <div>
            {/* leading business solution area */}
            <div className="rts-business-solution rts-business-solution5">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6 col-md-12 first-child">
                            <div className="rts-business-solution-right">
                                <div className="title-area">
                                     <h2 className="title">Leverage the power of code.</h2>
                                </div>
                                <div className="content-area">
                                    <p className="disc">
                                    We use a contemporary approach to produce remarkable 
                                    products to create the best 'human experience'.
                                    </p>
                                   
                                </div>


                                <div className="title-area mt-5">
                                     <h2 className="title"> What do we serve?</h2>
                                </div>
                                <div className="content-area ">
                                    <p className="disc">
                                    A full range of technology services that integrate with business and design.
                                    </p>
                                   
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            {/* business solution left */}
                            <div className="rts-business-solution-left">
                                <div className="thumbnail">
                                    <img src="assets/images/business-goal/03.jpg" alt="" />
                                    <div className="shape1">
                                        <img
                                            src="assets/images/business-goal/icon/setting.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="shape2">
                                        <img
                                            src="assets/images/business-goal/icon/setting.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="shape3">
                                        <img src="assets/images/business-goal/icon/bag.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            {/* business solution left End */}
                        </div>
                    </div>
                </div>
            </div>
            {/* leading business solution area End */}
        </div>
    )
}

export default MarketingSolution