import React from 'react'

function ContactFormThree() {

    const formselect = {
    height: "55px",
    background: "#F7F7F7",
    marginBottom: "20px",
    borderRadius: "15px",
    padding: "0 25px",
    border: "1px solid transparent",
    }
    const forminput = {
         display: "inline-block",
         background: "#F7F7F7", 
     cursor : "pointer",  
      height:"55px",
      marginBottom: "20px",
      borderRadius: "15px",
      marginLeft:"13px",
     transition : "background-color 0.3s"
        }

        const formselectCnrl = {opacity: "0",width:"100%"}
    return (
        <div>
            {/* contact area start */}
            <div className="rts-contact-area contact-one contact-four">
                <div className="container">
                    <div className="row align-items-center g-0">
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <div className="contact-image-one">
                                <img src="assets/images/contact/02.jpg" alt="" style={{ width: "90%" }} />
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                            <div className="contact-form-area-one">
                                <div className="rts-title-area contact text-start">
                                    <p className="pre-title" style={{color: "#df0a0a"}}>Join Our Team</p>
                                    <h2 className="title" >Shape the Future with Us</h2>
                                </div>
                                <div id="form-messages" />
                                <form id="contact-form" action="mailer.php" method="post">
                                    <div className="name-email">
                                        <input
                                            type="text"
                                            placeholder="Your Name"
                                            name="name"
                                            required=""
                                        />
                                        <input
                                            type="email"
                                            placeholder="Email Address"
                                            name="email"
                                            required=""
                                        />

                                    </div>
                                    <div className="name-email">
                                        <select name="" id="" style={formselect}>
                                            <option value="">Applying For</option>
                                            <option value="UI/UX Developer">UI/UX Desinger</option>
                                            <option value="UI/UX Developer">React Developer</option>
                                        </select>
                                        <div style={forminput}>
                                        <input  style={formselectCnrl}
                                            type="file"
                                            placeholder="Email Address"
                                            name="email"
                                            required=""
                                        />
                                        </div>
                                    </div>
                                    <input type="text" placeholder="Business Topic" />
                                    <textarea
                                        placeholder="Type Your Message"
                                        name="message"
                                        defaultValue={""}
                                    />
                                    <button type="submit" className="rts-btn btn-primary" style={{background:"#df0a0a",color:"#fff"}}>
                                        Submit Message
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* contact area end */}
        </div>
    )
}

export default ContactFormThree