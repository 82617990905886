import React from 'react'
import { Link } from 'react-router-dom';
import styled  from 'styled-components';

 
 
const SingleService = styled.div`
        position: relative;
        ma
    text-align: center;
    padding: 30px;
    margin-bottom: 30px;
    -webkit-transition: all 0.5s;
     overflow: hidden;
    transition: all 0.5s;
    border-radius: 4px; 
    z-index: 1;
    overflow: hidden;
    -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    margin: 18px 18px 36px;
    &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url('assets/images/serviceicons/hover.png'); /* Replace with your image */
    background-size: cover;
    background-position: center;
    transition: transform 0.4s ease;
    z-index: -1;
    transform: translateX(100%); /* Start off-screen */
}
    &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color:#ffecec; /* Hover background color */
    z-index: -2;
    transform: translateX(-100%); /* Start off-screen to the left */
    transition: transform 0.4s ease;
}


&:hover {
    color: #fff; /* Change text color on hover */
    background-color: #ffecec; /* Change background color on hover */
}
&:hover::before {
    transform: translateX(-0%); /* Move the image from right to left */
}

&:hover::after{
  transform: translateX(0);
}


`;


const ServiceImg = styled.div`
    margin-bottom: 20px;
   
    & > img {
     width: 42px;
     }
`;
const ServiceContent = styled.div`
& > h5{
    font-size: 21px;
    line-height: 1.5;
}

& > p {
    font-size: 15px;
        margin-bottom: 10px;
            height: auto;
    overflow: hidden;
}
`;

const  ServiceOne = () => {


    const Serviceinfo =  [
        {
            id: 1,
            servicehead: "Application Development",
            servicebody: "Elevate your business with a custom-built website, web app, or mobile application tailored specifically to your needs.",
            serviceimg: "assets/images/serviceicons/s1.png",
            link: "/application-development",
        },
        {
            id: 2,
            servicehead: "Quality Assurance & Software Testing",
            servicebody: "Our comprehensive quality assurance and software testing services ensure that every product we deliver is not only functional but also reliable and secure.",
            serviceimg: "assets/images/serviceicons/s2.png",
            link: "/Quality-assurance",
        },
        {
            id: 3,
            servicehead: "AI/ML",
            servicebody: "Leverage AI and machine learning algorithms to craft personalized customer journeys with intelligent automation and advanced AI capabilities.",
            serviceimg: "assets/images/serviceicons/s1.png",
            link: "/ai-ml-servicepage",
        },
        {
            id: 4,
            servicehead: "Data Analytics",
            servicebody: "Harness the power of cutting-edge machine learning technologies to transform raw data into actionable insights and gain a competitive edge in your industry.",
            serviceimg: "assets/images/serviceicons/s1.png",
            link: "/data-analytics",
        },
        {
            id: 5,
            servicehead: "DevOps & Automation",
            servicebody: "From eliminating repetitive manual tasks to deploying advanced machine learning algorithms, we enable your teams to operate with precision and speed.",
            serviceimg: "assets/images/serviceicons/s1.png",
            link: "/devops-and-automation",
        },
        {
            id: 6,
            servicehead: "Digital Marketing",
            servicebody: "Our Digital Marketing services are crafted to enhance your brand's online visibility and deliver substantial ROI with maximum return on each dollar spent.",
            serviceimg: "assets/images/serviceicons/s1.png",
            link: "/digital-marketing",
        },
        {
            id: 7,
            servicehead: "CRM",
            servicebody: "Revolutionize your Powersports operations with our cutting-edge CRM and seamlessly integrate tools to optimize and transform your dealership's efficiency.",
            serviceimg: "assets/images/serviceicons/s1.png",
            link: "/crm",
        },
        {
            id: 8,
            servicehead: "Marketing Automation",
            servicebody: "Transform Your Marketing with our Marketing Automation Portal exclusively built for Powersports Dealerships. Enhance your Dealership’s efficiency with automated resources.",
            serviceimg: "assets/images/serviceicons/s1.png",
            link: "/marketing-automation",
        },

        {
            id: 9,
            servicehead: "Enterprise Solution",
            servicebody: "RUnlock the power of seamless automation across the customer lifecycle, delivering content through dealer networks with ease to elevate every interaction.",
            serviceimg: "assets/images/serviceicons/s1.png",
            link: "/enterprise-solution",
        }
    ]
    
    return (
        <div>
            <>
                {/* rts service post area  Start*/}
                <div className=" ">
                    <div className="container-fluid service-main">
                         <div className="row  background-service"> 
                        <div className="col-12">
                            <div className="title-area-faq text-center">
                                <span className="sub pre-title" style={{color:"#df0a0a"}}>Our High Quality</span>
                                <h2 className="title">Services </h2>
                                 </div>
                                </div>
                            {Serviceinfo.map((serv) => (
                                <div className="col-lg-4 col-sm-6 text-center" key={serv.id} id={serv.id}>
                                    <SingleService>
                                        <ServiceImg>
                                            <img src={serv.serviceimg} alt="service" />
                                        </ServiceImg>
                                        <ServiceContent>
                                            <h5>{serv.servicehead}</h5>
                                            <p>{serv.servicebody}</p>
                                            <Link
                                            className="rts-read-more btn-primary "
                                            to={serv.link}
                                        >
                                            <i className="far fa-arrow-right styles" />
                                            Read more
                                        </Link>
                                        </ServiceContent>
                                    </SingleService>
                                </div>
                            ))}
                        </div>

                    </div>





 
                </div>
                {/* rts service post area ENd */}
            </>

        </div>
    )
}

export default ServiceOne