import React  from 'react'; 
import ContactFormThree from '../contactform/ContactFormThree';
import HeaderOne from '../header/HeaderOne';
import FooterOne from '../footer/FooterOne';
import FooterTwo from '../footer/FooterTwo'; 
import Breadcrumb from '../../inner/Breadcrumb';
// import { Form } from 'react-router-dom';
function CareerComp() {

    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Careers' }
    ];
     
    return (
        <div>
              <HeaderOne />
              <Breadcrumb title="Careers" breadcrumbs={breadcrumbs} />
           
            <ContactFormThree />
            
            <FooterTwo />
        </div>
    )
}

export default CareerComp;