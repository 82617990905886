import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Nav() {
    const [openSubmenus, setOpenSubmenus] = useState({});

    const toggleSubmenu = (key) => {
        setOpenSubmenus(prev => ({
            ...prev,
            [key]: !prev[key]
        }));
    };

    return (
        <div>
            <nav className="nav-main mainmenu-nav">
                <ul className="mainmenu">
                    <li>
                        <Link className="nav-link" to={'/'}>
                            Home
                        </Link>
                    </li>
                    <li className={`has-droupdown ${openSubmenus.solutions ? 'open' : ''}`}>
                        <Link className="nav-link" to={'#'} onClick={() => toggleSubmenu('solutions')}>
                            Solutions
                        </Link>
                        {openSubmenus.solutions && (
                            <ul className="pl--15">
                                <li className="sub-droupdown">
                                    <Link to={'/ai-ml-servicepage'}>
                                        AI / ML
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/omni-channel'}>Omnichannel</Link>
                                </li>
                                <li>
                                    <Link to={'/crm'}>CRM</Link>
                                </li><li>
                                    <Link to={'/digital-marketing'}>Digital Marketing</Link>
                                </li>
                            </ul>
                        )}
                    </li>
                    <li className={`has-droupdown ${openSubmenus.products ? 'open' : ''}`}>
                        <Link className="nav-link" to={'#'} onClick={() => toggleSubmenu('products')}>
                            Products
                        </Link>
                        {openSubmenus.products && (
                            <ul className='pl--15'>
                                <li className="sub-droupdown">
                                    <Link to={'https://www.ready2ridemobile.com'} target="_blank">Ready2Ride</Link>
                                </li>
                                <li>
                                    <Link to={'https://clicmotion.com'} target="_blank">ClicMotion</Link>
                                </li>
                                <li>
                                    <Link to={'https://ready2boatride.com'} target="_blank">Ready2BoatRide</Link>
                                </li>
                                <li>
                                    <Link to={'https://clictell.com'} target="_blank">Clictell</Link>
                                </li>
                                <li>
                                    <Link to={'https://ready2rv.com'} target="_blank">Ready2RV </Link>
                                </li>
                            </ul>
                        )}
                    </li>
                    <li>
                        <Link className="nav-item" to={'/technology'}>
                            Technology
                        </Link>
                    </li>
                    <li className={`has-droupdown ${openSubmenus.company ? 'open' : ''}`}>
                        <Link className='nav-link' to={'#'} onClick={() => toggleSubmenu('company')}>
                            Company
                        </Link>
                        {openSubmenus.company && (
                            <ul className="pl--15">
                                <li className="sub-droupdown">
                                    <Link to={'/about-us'}>
                                        Overview
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/careers'}>Careers</Link>
                                </li>
                                <li>
                                    <Link to={'/faq'}>FAQ</Link>
                                </li>
                                {/* <li>
                                    <Link to={'/testimonial'}>Testimonials</Link>
                                </li> */}
                            </ul>
                        )}
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Nav