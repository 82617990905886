import React, { useState } from 'react';
import { Tabs, Tab, TabContent } from 'react-bootstrap'; 
function AboutTwo() {
    const [activeKey, setActiveKey] = useState('home1');

 
    return (
        <div>
            {/* start about our company */}
            <div className="rts-about-our-company-h2 rts-section-gap" id='aboutus'>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 order-xl-1 order-lg-1 order-md-2 order-sm-2 order-2 mt_sm--30 align-self-center">
                            
                            <div className="about-company-wrapper ">
                                <div className="rts-tab-style-one">
                                    <div className=" align-items-start ">

                                        <Tabs
                                            activeKey={activeKey}
                                            onSelect={(k) => setActiveKey(k)}
                                            id="controlled-tab-example"
                                            className="mb-3 nav "
                                        >
                                            <Tab eventKey="home1" title="Who we are ?" />
                                            <Tab eventKey="profile1" title="Warrous History" style={{color:"red"}}/>
                                            <Tab eventKey="contact1" title="Our Mission" style={{color:"red"}}/>
                                            <Tab eventKey="vision1" title="Our Vision" style={{color:"red"}}/>
                                        </Tabs>
                                        {activeKey === 'home1' && (
                                            <TabContent>
                                                {/* start tab content */}
                                                <div className="row">
                                                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 order-xl-1 order-lg-1 order-md-2 order-sm-2 order-2 mt_sm--30">

                                                        <div className="rts-tab-content-one">
                                                            <h5 className="title">  About Us  </h5>
                                                            <p className="disc">
                                                                Warrous believes in harnessing the power of Data and Technology to target new growth opportunities. We wanted to create a compelling, authentic experience that reflects our capabilities. Transforming Intelligently With our technology expertise and our team of industry-leading consultants, we can help you achieve every goal on your roadmap. We believe in providing our customers with a level of service and personal care, making the customer happy is in our DNA. Our talented, dynamic and experienced professionals efficiently use their technical expertise to enable high end solutions for Web and Software Development to that end, our specialists are always looking for new ways to solve our clients issues quickly, easily and economically. Improve the efficiency
                                                                and effectiveness of your Business and processing operations.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 order-xl-1 order-lg-1 order-md-1 order-sm-1 order-1">
                                                        <div className="about-company-thumbnail">
                                                            <img src="assets/images/about/01.png" alt="Business_company" />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* start tab content End */}
                                            </TabContent>
                                        )}
                                        {activeKey === 'profile1' && (
                                            <TabContent>

                                                <div className="row">
                                                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 order-xl-1 order-lg-1 order-md-2 order-sm-2 order-2 mt_sm--30">

                                                        <div className="rts-tab-content-one">
                                                            <h5 className="title"> Our Story </h5>

                                                            <p className="disc">
                                                                Engage and Promote your business with Warrous's services. Our promoting experts work with you to design and execute a system that conveys results. We provide great tools for creating great campaigns, with campaign management get the word out with free email templates, Ab testing and social campaigns etc., also automate and customize your email campaigns with our editor.
                                                            </p>

                                                            <p className="disc">Get master promoting help where you require it, consistently. A committed expect marketing consultant helps you in maximizing results and gives you an ace amount of time to focus on important areas of your business.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 order-xl-1 order-lg-1 order-md-1 order-sm-1 order-1">
                                                        <div className="about-company-thumbnail">
                                                            <img src="assets/images/about/01.png" alt="Business_company" />
                                                        </div>
                                                    </div>
                                                </div>



                                            </TabContent>
                                        )}

                                        {activeKey === 'contact1' && (
                                            <TabContent>

                                                <div className="row">
                                                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 order-xl-1 order-lg-1 order-md-2 order-sm-2 order-2 mt_sm--30">

                                                        <div className="rts-tab-content-one">
                                                            <h5 className="title">  Our Mission </h5>
                                                            <p className="disc">
                                                                Our mission is to deliver optimal solutions with quality and services at reasonable prices. For us customer satisfaction is given top place. We are very friendly in our dealings to the customers and it helps us retain existing clients and expand customer circle. We always try
                                                                to improve the quality of our services by exploring innovative ideas.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 order-xl-1 order-lg-1 order-md-1 order-sm-1 order-1">
                                                        <div className="about-company-thumbnail">
                                                            <img src="assets/images/about/01.png" alt="Business_company" />
                                                        </div>
                                                    </div>
                                                </div>

                                            </TabContent>
                                        )}

                                        {activeKey === 'vision1' && (
                                            <TabContent>
                                                <div className="row">
                                                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 order-xl-1 order-lg-1 order-md-2 order-sm-2 order-2 mt_sm--30">

                                                        <div className="rts-tab-content-one">
                                                            <h5 className="title">  Our Vision</h5>
                                                            <p className="disc">
                                                                We have been able to bring together a talented crew of professionals shaped and molded by their collective experiences in the agency, corporate and private Solutions, all of which possess outstanding talent. The synergy of what Northwhiz does comes from a blend of passion for success and the skill to help accelerate your organization.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 order-xl-1 order-lg-1 order-md-1 order-sm-1 order-1">
                                                        <div className="about-company-thumbnail">
                                                            <img src="assets/images/about/01.png" alt="Business_company" />
                                                        </div>
                                                    </div>
                                                </div>



                                            </TabContent>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/* <ContactFormThree /> */}

            </div>
            {/* start about our company End */}

        </div>
    )
}

export default AboutTwo