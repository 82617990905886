import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "./Breadcrumb";
import { Link } from 'react-router-dom';



import FooterOne from "../components/footer/FooterTwo";

function DataAnalytics() {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Data Analytics' }
    ];
    return (
        <div className=''>

            <HeaderOne />
            <Breadcrumb title="Data Analytics" breadcrumbs={breadcrumbs} />

            {/* start service details area */}
            <div className="rts-service-details-area rts-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                            {/* service details left area start */}
                            <div className="service-detials-step-1">

                                <div className="row">
                                    <div className="col-sm-7  align-self-center">
                                        <div className="thumbnail">
                                            <img src="assets/images/service/01.jpg" alt="business-area" />
                                        </div>
                                    </div>
                                    <div className="col-sm-5 align-self-center">
                                        <h4 className="title text-end">Turning Data into Strategic Insights</h4>

                                        <div style={{
                                            background: "rgb(255 236 236)", padding: "25px", marginBottom: "25px", lineHeight: "36px",
                                            border: "1px solid #ffecec", borderRadius: "9px",
                                            display: "flex", justifyContent: "center",
                                            alignItems: "center", textAlign: "right"
                                        }}>

                                            <i style={{ fontSize: "24px", fontWeight: "400", color: "#000" }}>Empowering businesses with Data Analytics solutions that transform raw data into actionable insights. </i>
                                        </div>
                                    </div>
                                </div>

                                <h4 className="title">Data Analytics</h4>
                                <p className="disc">
                                We employ powerful tools and cutting-edge analytics models to help identify patterns, trends, and correlations within complex data sets. This enables businesses to understand customer behaviour, forecast market trends, and refine operational processes. By leveraging advanced data processing and analysis techniques, we help organizations make informed decisions, optimize strategies, and uncover new growth opportunities.                                </p>
                                <p className="disc">
                                Our Data Analytics services cover the entire data lifecycle, from data collection and cleaning to visualization and predictive modelling, ensuring that every step is geared towards delivering accurate and meaningful insights. 
                                </p>
                                <p className="disc">
                                Our approach to data analytics is flexible and tailored to meet each client's unique needs. We work closely with your team to ensure data integrity, implementing customized dashboards and visualization tools that make data easy to interpret and accessible in real time.                                </p>
                            </div>

                            {/* service details left area end */}
                            <div className="service-detials-step-3 mt--70 mt_md--50">
                                <div className="row g-5 align-items-center">
                                    <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="thumbnail sm-thumb-service">
                                            <img src="assets/images/service/sm-01.jpg" alt="Service" />
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 mb_md--20 mb_sm--20">
                                        <h4 className="title">Why Choose Us?</h4>
                                        <p className="disc">
                                            With our expertise, your business can gain a competitive edge, turning data into a strategic asset that drives sustainable growth and innovation focusing on long-term growth and innovation, we provide ongoing support, helping you adapt to changing data needs and emerging trends.</p>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="cta-one-bg col-12">
                                    <div className="cta-one-inner pt-5 pb-5">
                                        <div className="cta-left">
                                            <h3 className="title">
                                                Transform Your Business Today with Ready2Ride!
                                            </h3>
                                        </div>
                                        <div className="cta-right">
                                            <Link className="rts-btn btn-white" to={'https://clictechnologies.com/'} target="_blank">
                                                Contact Us
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* End service details area */}

            <FooterOne />

        </div>
    )
}

export default DataAnalytics;