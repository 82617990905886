import React from 'react'
import { Link } from 'react-router-dom';
function MapOne() {
    return (
        <div>
            {/* map area start */}
            <div className="rts-map-area bg-light-white" id='address'>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            {/* map area left */}
                            <div className="mapdetails-inner-one">
                                <div className="left-area single-wized">
                                    <h5 className="title">Get in touch</h5>
                                    <div className="details">
                                        <p>Work and general inquiries</p>
                                        <Link className="number" to={'#'}>
                                        +91 8333872345
                                        </Link>
                                        <p className="time-header">Working hours:</p>
                                        <p className="time">
                                            Monday – Friday <br /> 11 am to 9 pm IST
                                        </p>
                                    </div>
                                </div>
                                <div className="right-area single-wized">
                                    <h5 className="title">Address</h5>
                                    <div className="details">
                                        <Link to={'https://www.google.com/maps/place/Warrous/@17.4346378,78.374299,17z/data=!3m1!4b1!4m6!3m5!1s0x3bcb915ae395fdf1:0x87433c67015f0b1a!8m2!3d17.4346327!4d78.3768739!16s%2Fg%2F11f4__4y9t?entry=ttu&g_ep=EgoyMDI0MTAyMy4wIKXMDSoASAFQAw%3D%3D'} target='_blank'>
                                        6th floor, Wing - A, Orbit by Auro Reality, Unit #11 The Headquarters, Knowledge City Rd, Rai Durg, Hyderabad, Telangana 500019
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/* map area right */}
                        </div>
                        <div className="col-lg-6">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.5235568373937!2d78.3742989746841!3d17.434637801443152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb915ae395fdf1%3A0x87433c67015f0b1a!2sWarrous!5e0!3m2!1sen!2sin!4v1730209434225!5m2!1sen!2sin"
                                width={600}
                                height={450}
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* map area end */}
        </div>
    )
}

export default MapOne