import React from 'react'
import { Link } from 'react-router-dom';
function FooterTwo() {
    return (
        <div>
            <>
                {/* footer area start */}
                <div className="rts-footer-area pt--50 pb--50 footer-four footer-bg-two" id='f-contact'>
                    <div className="container">
                        <div className="row">
                            {/* single wized */}
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 mt_sm--50">
                                <div className="footer-three-single-wized left">
                                    <Link to={'/'} className="logo_footer">
                                        <img src="assets/images/logo/footer-logo.png" width={'200'} alt="" />
                                    </Link>
                                    <p className="disc">
                                        Warrous believes in harnessing the power of Data and Technology to target new growth opportunities. We wanted to create a compelling, authentic experience that reflects our capabilities.
                                    </p>
                                    <ul className="social-three-wrapper">
                                        <li>
                                            <Link to={'https://www.linkedin.com/company/warrous-pvt-ltd/'} target='_blank'>
                                                <i className="fab fa-linkedin-in" />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 mt_sm--50">
                                <div className="footer-two-single-wized two">
                                    <div className="wized-title text-light-area">
                                        <h5 className="wized-title text-light">Our Solutions</h5>
                                        {/* <hr /> */}
                                    </div>
                                    <div className="wized-2-body">
                                        <ul>
                                            <li>
                                                <Link to={'/ai-ml-servicepage'}>
                                                    <i className="fal fa-chevron-double-right" /> AI / ML
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/omni-channel'}>
                                                    <i className="fal fa-chevron-double-right" /> Omnichannel</Link>
                                            </li>
                                            <li>
                                                <Link to={'/crm'}>
                                                    <i className="fal fa-chevron-double-right" /> CRM</Link>
                                            </li>
                                            <li>
                                                <Link to={'/digital-marketing'}>
                                                    <i className="fal fa-chevron-double-right" /> Digital Marketing</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 mt_sm--50">
                                <div className="footer-two-single-wized two">
                                    <div className="wized-title text-light-area">
                                        <h5 className="wized-title text-light">Our Products</h5>
                                        {/* <hr /> */}
                                    </div>
                                    <div className="wized-2-body">
                                        <ul>
                                            <li className="sub-droupdown">
                                                <Link to={'https://www.ready2ridemobile.com'} target="_blank">
                                                    <i className="fal fa-chevron-double-right" /> Ready2Ride</Link>
                                            </li>
                                            <li>
                                                <Link to={'https://clicmotion.com'} target="_blank">
                                                    <i className="fal fa-chevron-double-right" /> ClicMotion</Link>
                                            </li>
                                            <li>
                                                <Link to={'https://ready2boatride.com'} target="_blank">
                                                    <i className="fal fa-chevron-double-right" /> Ready2BoatRide</Link>
                                            </li>
                                            <li>
                                                <Link to={'https://clictell.com'} target="_blank">
                                                    <i className="fal fa-chevron-double-right" /> Clictell</Link>
                                            </li>
                                            <li>
                                                <Link to={'https://ready2rv.com'} target="_blank">
                                                    <i className="fal fa-chevron-double-right" /> Ready2RV </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* single wized */}
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 mt_sm--30 mt_md--30">
                                <div className="footer-four-single-wized">
                                    <div className="wized-title text-light-area">
                                        <h5 className="wized-title text-light">Contact Us</h5>
                                    </div>
                                    <div className="wized-3-body">
                                        <div className="contact-info-1">
                                            <div className="icon">
                                                <i className="fas fa-phone-alt" />
                                            </div>
                                            <div className="disc">
                                                <span>Call Us 24/7</span>
                                                <Link to={'tel:+918333872345'}>+91 8333 872 345</Link>
                                            </div>
                                        </div>
                                        <div className="contact-info-1">
                                            <div className="icon">
                                                <i className="fas fa-envelope" />
                                            </div>
                                            <div className="disc">
                                                <span>Work with us</span>
                                                <Link to={'mailto:info@warrous.com'}>info@warrous.com</Link>
                                            </div>
                                        </div>
                                        <div className="contact-info-1">
                                            <div className="icon">
                                                <i className="fas fa-map-marker-alt" />
                                            </div>
                                            <div className="disc">
                                                <span>Our Location</span>
                                                <Link to={'https://www.google.com/maps/place/Warrous/@17.4346378,78.374299,17z/data=!3m1!4b1!4m6!3m5!1s0x3bcb915ae395fdf1:0x87433c67015f0b1a!8m2!3d17.4346327!4d78.3768739!16s%2Fg%2F11f4__4y9t?entry=ttu&g_ep=EgoyMDI0MTAyMy4wIKXMDSoASAFQAw%3D%3D'} target='_blank'>
                                        6th floor, Wing - A, Orbit by Auro Reality, Unit #11 The Headquarters, Knowledge City Rd, Rai Durg, Hyderabad, Telangana 500019
                                        </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* single wized */}
                        </div>
                    </div>
                </div>
                {/* footer area end */}
                {/* copyright-area start */}
                <div className="rts-copy-right ptb--30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="copyright-h-2-wrapper">
                                    <p className="disc">
                                        Warrous - Copyright 2024. All rights reserved.
                                    </p>
                                    <div className="right">
                                        <ul>
                                            <li>
                                                <Link href="/">Terms of Use</Link>
                                            </li>
                                            <li>
                                                <Link to={'/'}>Privacy Policy</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* copyright-area end */}
            </>

        </div>
    )
}

export default FooterTwo