import React from 'react';

import styled, { keyframes } from 'styled-components';
import './styles.css';

const ProSpan = styled.span`
    position: absolute;
    z-index: 1;

    &::before {
        content: "";
        position: absolute;
        height: 170px; /* Adjust as needed */
        border-right: 1px dashed #df0a0a;
        transform: rotate(90deg);
        top: -55px;
        left: 91px;
        z-index: -1;
    }

    ${props => props.isLast && `
        &::before {
            content: none; /* Remove the before element for the last child */
        }
    `}
`;


const leftani = keyframes`
    0% {
        transform: translateX(-15px);
    }
    50% {
        transform: translateX(15px);
    }
    100% {
        transform: translateX(-15px);
    }
`;

const ShapeImg = styled.img`
    max-width: 100px;
    margin-left: 50px;
    animation: ${leftani} 5s infinite linear;
 display: ${props => (props.isLast ? 'none' : 'inline')}; 
    
`;

const OurServiceComp = () => {
    const singleprocess = {
        textAlign: "center",
        marginBottom: "30px",
        position: "relative",
    };

    const h3div = {
        fontSize: "21px",
        lineHeight: "1.5",
    };
    const aniwrapper = {
        background: "#df0a0a24",
        display: "inline-block",
        width: "60px",
        height: "60px",
        lineHeight: "56px",
        marginBottom: "30px",
        borderRadius: "3px",
        position: "relative",
    }


    return (
        <div className="rts-service-area rts-section-gapBottom rts-section-gapTop">
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div className="title-area-faq text-center">
                            <span className="sub pre-title" style={{ color: "#df0a0a" }}>Working Process</span>
                            <h2 style={{ fontSize: "21px" }}>Our unique approach sets us apart, and our way of working defines our reputation. </h2>
                        </div>

                    </div>
                </div>
                <div className="row justify-content-center">
                    {['Plan', 'Design', 'Develop', 'Test', 'Support'].map((title, index) => (
                        <div className="col-lg-2 col-sm-6" key={index}>
                            <div style={singleprocess}>
                                <div style={aniwrapper}>
                                    <img src={`assets/images/process/p${index + 1}.png`} alt="process" />
                                    <ProSpan isLast={index === 4}>
                                        <ShapeImg src="assets/images/process/next.png" alt="shape" isLast={index === 4} />
                                    </ProSpan>
                                </div>
                                <div className="content text">
                                    <h3 style={h3div}>{title}</h3>
                                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p> */}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>


            </div>
        </div>
    );
};

export default OurServiceComp;
