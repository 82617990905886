import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "./Breadcrumb";
import { Link } from 'react-router-dom';



import FooterOne from "../components/footer/FooterTwo";

function IntegratedMarkettingCom() {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Integrated Marketing' }
    ];
    return (
        <div className=''>

            <HeaderOne />
            <Breadcrumb title="Integrated Marketing" breadcrumbs={breadcrumbs} />

            {/* start service details area */}
            <div className="rts-service-details-area rts-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                            {/* service details left area start */}
                            <div className="service-detials-step-1">

                                <div className="row">
                                    <div className="col-sm-7  align-self-center">
                                        <div className="thumbnail">
                                            <img src="assets/images/service/01.jpg" alt="business-area" />
                                        </div>
                                    </div>
                                    <div className="col-sm-5 align-self-center">
                                        <h4 className="title text-end">Amplify Your Brand with Cohesive Strategies</h4>

                                        <div style={{
                                            background: "rgb(255 236 236)", padding: "25px", marginBottom: "25px", lineHeight: "36px",
                                            border: "1px solid #ffecec", borderRadius: "9px",
                                            display: "flex", justifyContent: "center",
                                            alignItems: "center", textAlign: "right"
                                        }}>

                                            <i style={{ fontSize: "24px", fontWeight: "400", color: "#000" }}>Harness integrated marketing to deliver consistent, impactful messages across every channel.</i>
                                        </div>
                                    </div>
                                </div>

                                <h4 className="title">Integrated Marketing</h4>
                                <p className="disc">
                                Our Integrated Marketing solutions are designed to align your brand’s voice and visuals across digital, social, and traditional media, ensuring a cohesive customer experience that enhances brand recognition and engagement. We create a unified brand presence across platforms, building credibility and making your brand instantly recognizable to your audience.
                                </p>
                                <p className="disc">
                                Our integrated approach allows for comprehensive analytics, giving you a full view of customer behavior across channels to fine-tune your strategy continually. Our integrated campaigns create a more engaging and memorable experience, encouraging stronger connections and customer loyalty.
                                </p>

                            </div>

                            {/* service details left area end */}
                            <div className="service-detials-step-3 mt--70 mt_md--50">
                                <div className="row g-5 align-items-center">
                                    <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="thumbnail sm-thumb-service">
                                            <img src="assets/images/service/sm-01.jpg" alt="Service" />
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 mb_md--20 mb_sm--20">
                                        <h4 className="title">Why Choose Us?</h4>
                                        <p className="disc">
                                        At Warrous, your brand gains the advantage of a synchronized marketing approach that is flexible, data-driven, and adaptable to changing trends. We are dedicated to helping you achieve long-term growth and engagement through a strategy that delivers a clear, consistent message—no matter where your customers interact with your brand.
                                        </p>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="cta-one-bg col-12">
                                    <div className="cta-one-inner pt-5 pb-5">
                                        <div className="cta-left">
                                            <h3 className="title">
                                                Ready to Amplify Your Brand’s Reach?
                                            </h3>
                                        </div>
                                        <div className="cta-right">
                                            <Link className="rts-btn btn-white" href="appoinment.html">
                                                Call Our Professional Experts Today! 
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* End service details area */}

            <FooterOne />

        </div>
    )
}

export default IntegratedMarkettingCom;