import React from 'react'

const technologies = [
    {
        "category": "Front End Technologies",
        "items": [
            { "name": "Angular", "image": "/assets/images/technologies/Angular.svg" },
            { "name": "HTML", "image": "/assets/images/technologies/html5.png" },
            { "name": "CSS", "image": "/assets/images/technologies/css3.png" },
            { "name": "React.js", "image": "/assets/images/technologies/d.image6.svg" },
        ]
    },
    {
        "category": "Back End Technologies",
        "items": [
            { "name": "SQL Database", "image": "/assets/images/technologies/sql-database-generic.png" },
            { "name": "Azure Functions", "image": "/assets/images/technologies/azure service 1.png" },
            { "name": "AI/ML", "image": "/assets/images/technologies/d.image1.png" },
            { "name": "Node.js", "image": "/assets/images/technologies/nodejs.png" },
            { "name": "C#", "image": "/assets/images/technologies/csharp-logo.png" },
            { "name": "Micro Services", "image": "/assets/images/technologies/download.png" },
            { "name": "Azure Data Lake", "image": "/assets/images/technologies/azure data lake.png" },
            { "name": "Delta Lake", "image": "/assets/images/technologies/d.image7.png" },
            { "name": "Python", "image": "/assets/images/technologies/Python-logo-notext.svg.png" },
            { "name": "Azure Data Factory", "image": "/assets/images/technologies/d.image2.jpg" },
            { "name": "Logic Apps", "image": "/assets/images/technologies/Icon-integration.png" },
            { "name": "Apache Spark", "image": "/assets/images/technologies/d.image16.png" },
            { "name": "PySpark", "image": "/assets/images/technologies/pyspark2.png" },
        ]
    },
    {
        "category": "Cloud Technologies",
        "items": [
            { "name": "Azure", "image": "/assets/images/technologies/azure.png" },
            { "name": "AWS", "image": "/assets/images/technologies/aws-cloud.png" },
        ]
    },
    {
        "category": "DevOps",
        "items": [
            { "name": "Azure Devops", "image": "/assets/images/technologies/AzureDevops.svg" },
        ]
    },
    {
        "category": "Testing & QA",
        "items": [
            { "name": "Selenium", "image": "/assets/images/technologies/selenium.png" },
            { "name": "Cucumber", "image": "/assets/images/technologies/cucumber.png" },
            { "name": "Apache jMeter", "image": "/assets/images/technologies/jmeter.png" },
        ]
    },
    {
        "category": "Integrations",
        "items": [
            { "name": ".Net", "image": "/assets/images/technologies/D.IMAGE12.png" },
            { "name": "Power BI", "image": "/assets/images/technologies/powerBi.png" },
            { "name": "SendGrid", "image": "/assets/images/technologies/d.image13.png" },
            { "name": "SparkPost", "image": "/assets/images/technologies/SparkPost_Built-By-Logo_2-Color_Gray-Orange.png" },
            { "name": "NetSuite", "image": "/assets/images/technologies/netsuite.png" },
            { "name": "Zendesk", "image": "/assets/images/technologies/d.image15.png" },
            { "name": "Papirfly", "image": "/assets/images/technologies/papirfly.png" },
            { "name": "xTime", "image": "/assets/images/technologies/xTime-.png" },
            { "name": "SimpleTexting", "image": "/assets/images/technologies/simple texting.png" },
        ]
    },
    {
        "category": "Data Integrations",
        "items": [
            { "name": "CDK Global", "image": "/assets/images/technologies/cdk globel.png" },
            { "name": "Fortellis", "image": "/assets/images/technologies/Fortellis.jpg" },
            { "name": "Dealer Track", "image": "/assets/images/technologies/dealerTrack.png" },
        ]
    },
    {
        "category": "Mobile Apps",
        "items": [
            { "name": "Android", "image": "/assets/images/technologies/android-logo-5-2.png" },
            { "name": "iOS", "image": "/assets/images/technologies/d.image17.png" },
            { "name": "React Native", "image": "/assets/images/technologies/d.image8.svg" },
        ]
    },
    {
        "category": "Digital Marketing",
        "items": [
            { "name": "Google AdWords", "image": "/assets/images/technologies/google-ads.png" },
            { "name": "Google Analytics", "image": "/assets/images/technologies/Google-Analytics-Symbol.png" },
            { "name": "SEO", "image": "/assets/images/technologies/seo.png" },
            { "name": "SEM Rush", "image": "/assets/images/technologies/semrush.png" }
        ]
    }
]

function BrandFive() {
    return (
        <>
            <div className="rts-trusted-client rts-trusted-client2 rts-section-gapBottom">
                <div className="container">
                    {technologies.map((tech, index) => (
                        <div key={index}>
                            <div className="row">
                                <div className="col-12 p-0">
                                    <div className="title-area-client">
                                        <p className="client-title text-dark">{tech.category}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3 mb-5 techCards">
                                {tech.items.map((item, itemIndex) => (
                                    <div className="col-sm-2" key={itemIndex}>
                                        <div className="bg-white card mb-4 shadow w-100">
                                            <div className="card-body text-center">
                                                <img src={item.image} alt="" />
                                                <p className="fw-bold small text-black border-1 border-top mt-4">{item.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default BrandFive