import React  from 'react';
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "./Breadcrumb"; 
import FooterOne from "../components/footer/FooterTwo"; 
import MarketingSolution from '../components/businessgoal/MarketingSolution';
import BrandFive from "../components/brand/BrandFive";
import BusinessGoalFour from '../components/businessgoal/BusinessGoalFour';


function TechnologyCmp() {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Technology' }
    ];
      return (
        <div className=''>

            <HeaderOne />
            <Breadcrumb title="Technology" breadcrumbs={breadcrumbs} />
            {/* rts about us section start */}
            <MarketingSolution />
            <BrandFive />
            <BusinessGoalFour />
            <div style={{paddingTop:"82px"}}></div>
            <FooterOne/>

        </div>
    )
}

export default TechnologyCmp;